import { defineStore } from "pinia";

export const clientRoadmapStore = defineStore('client_roadmap_store', {
    state: () => ({
        staffs: {},
        roadmaps: [],
        tasks: []
    }),
    getters: {
        getStaffs: (state) => state.staffs,
        getRoadmaps: (state) => state.roadmaps,
        getTasks: (state) => state.tasks
    },
    actions: {
        setStaffs(payload) {
            this.staffs = payload
        },
        setRoadmapData(payload) {
            this.roadmaps = [...payload]
        },
        updateRoadmapInternalUser(payload) {
            const roadmap = this.roadmaps.find( (roadmap) => roadmap.id === payload.parent_id)                      
            const child = roadmap.get_child.find( (child) => child.id == payload.id)
            child.tagged_to = payload.tagged_to
        },
        updateRoadmapPriority(payload) {
            const roadmap = this.roadmaps.find( (roadmap) => roadmap.id === payload.parent_id)                      
            const child = roadmap.get_child.find( (child) => child.id == payload.id)
            child.color = payload.color
        },
        updateRoadmapProgress(payload) {
            const roadmap = this.roadmaps.find( (roadmap) => roadmap.id === payload.parent_id)                      
            const child = roadmap.get_child.find( (child) => child.id == payload.id)
            child.progress = payload.progress
            child.ischecked = payload.ischecked
        },
        updateRoadmapDeadline(payload) {
            const roadmap = this.roadmaps.find( (roadmap) => roadmap.id === payload.parent_id)                      
            const child = roadmap.get_child.find( (child) => child.id == payload.id)
            child.deadline_date = payload.deadline_date
        },
        updateRoadmapName(payload) {
            const roadmap = this.roadmaps.find( (roadmap) => roadmap.id === payload.parent_id)            
            const child = roadmap.get_child.find( (child) => child.id == payload.id)
            child.name = payload.name
        },
        updateRoadmapLink(payload) {
            const roadmap = this.roadmaps.find( (roadmap) => roadmap.id === payload.parent_id)            
            const child = roadmap.get_child.find( (child) => child.id == payload.id)
            child.video_url = payload.video_url
        },
        // Tasks
        setTasks(payload) {
            this.tasks = [...payload]
        },
        updateTaskStatus(payload) {
            const task = this.tasks.find( (item) => item.id == payload.id)
            console.log(task)
            task.is_done = payload.is_done
        },
        addNewTask(payload) {
            this.tasks.push(payload)
        }
    }
})