import { computed } from 'vue'
import RoadmapClass from '../../../modules/classes/roadmap.class'

export default class EditRoadmapComputed {
    constructor() {
        this.roadmap_class = new RoadmapClass()
    }

    computed_properties = ( () => {

        const getTasksFromStore = computed( () => {
            return this.roadmap_class.getTasksFromStore()
        })

        return {
            getTasksFromStore
        }
    })()
}