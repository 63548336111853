import RoadmapClass from "../../../modules/classes/roadmap.class"

export default class EditRoadmapMethods {
    
    constructor(parameters) {
        this.emit = parameters.emit
        this.selected_roadmap = parameters.selected_roadmap
        this.selected_priority = parameters.selected_priority
        this.selected_progress = parameters.selected_progress
        this.selected_personnel = parameters.selected_personnel

        // Loading Variables
        this.roadmap_name_loading = parameters.roadmap_name_loading        
        this.roadmap_removelink_loading = parameters.roadmap_removelink_loading
        this.roadmap_addlink_loading = parameters.roadmap_addlink_loading
        
        // Classes
        this.roadmap_class = new RoadmapClass()
    }

    methods = ( () => {

        const closeEditRoadmapDialog = () => {
            this.selected_priority.value = ''
            this.selected_progress.value = '--'   
            this.selected_personnel.value = null         
            this.emit('closeEditRoamdapDialog')
        }

        const editRoadmapName = async (event) => {
            const edited_name = event.target.value
            if ( event.keyCode == 13 ) {  
                const data = {
                    edited_name: edited_name,
                    ...this.selected_roadmap.value
                }
                this.roadmap_name_loading.value = true                             
                await this.roadmap_class.editRoadmapName(data)
                this.roadmap_name_loading.value = false
            }
        }

        const removeVideolLink = async (roadmap_data) => {
            this.roadmap_removelink_loading.value = true
            await this.roadmap_class.removeRoadmapVideoLink(roadmap_data)
            this.roadmap_removelink_loading.value = false
        }

        const addVideoLink = async (roadmap_data, video_url) => {
            this.roadmap_addlink_loading.value = true
            await this.roadmap_class.addRoadmapVideoLink({
                data: roadmap_data,
                item: video_url
            })
            this.roadmap_addlink_loading.value = false
        }

        /** Tasks Management */
        const getTasks = (data) => {
            this.roadmap_class.getRoadmapTasks(data)
        }

        const updateTaskStatus = (data) => {
            this.roadmap_class.setTasksStatus(data)
        }

        const addTask = (event) => {

            if ( event.keyCode === 13 ) {
                const data = {
                    course_id: this.selected_roadmap.value.course_id,
                    is_parent: this.selected_roadmap.value.is_parent,
                    roadmap_id: this.selected_roadmap.value.id,
                    name: this.selected_roadmap.value.name,               
                    user_id: this.selected_roadmap.value.user_id,
                    task: event.target.value
                }
                this.roadmap_class.addNewTask(data)
                event.target.value = null
            }
        }

        const removeTaskEntry = (task) => {
            this.roadmap_class.removeTask(task)
        }

        /** Assigned Internal User */
        const getAssignedUserInitials = (username)  => {
            return username == null
                ? "---"
                : username
                    .split(" ")
                    .map((x) => x[0])
                    .join("");
        }       

        /** Deadline */
        const setDeadlineDate = (deadline_date) => {
            this.roadmap_class.setDeadline({
                data: this.selected_roadmap.value,
                item: deadline_date
            })
        }

        /** Progress */
        const setProgress = (progress) => {
            if ( progress == '-') {
                progress = null
            } else {
                progress = progress.replace('%', '')
            }

            this.roadmap_class.setRoadmapProgress({
                data: this.selected_roadmap.value,
                item: progress
            })
        }

        /** Set Priority */
        const setPriority = (priority) => {
            const payload = {
                color: priority.color,
                course_id: this.selected_roadmap.value.course_id,
                item_id: this.selected_roadmap.value.id
            }
            this.roadmap_class.setRoadmapPriority(payload)
        }

        /** Set Internal User */
        const setInternalUser = (user) => {                  
            const payload = {
                course_id: this.selected_roadmap.value.course_id,
                internalUser: user.name,
                roadmap_id: this.selected_roadmap.value.id
            }
            this.roadmap_class.setRoadmapInternalUser(payload)
        }

        const removeInternalUser = () => {
            this.selected_personnel.value = null
            this.roadmap_class.removeRoadmapInternalUser(this.selected_roadmap.value.id)
        }

        return  {
            closeEditRoadmapDialog,
            editRoadmapName, 
            removeVideolLink,
            addVideoLink,
            getTasks,
            updateTaskStatus,
            addTask,
            removeTaskEntry,
            getAssignedUserInitials,
            setDeadlineDate,
            setProgress,
            setPriority,
            setInternalUser,
            removeInternalUser
        }
    })()
}